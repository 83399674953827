import React from 'react';
import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TestModeBanner = () => {
  const { t } = useTranslation();
  const isTestMode = process.env.REACT_APP_STRIPE_MODE === 'test';

  if (!isTestMode) return null;

  return (
    <Alert severity="info" sx={{ mb: 2 }}>
      {t('payment.testMode')}
      <br />
      {t('payment.testCard')}: 4242 4242 4242 4242
    </Alert>
  );
};

export default TestModeBanner; 