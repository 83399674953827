import React, { useState, useEffect } from 'react';
import StripePaymentForm from './StripePaymentForm';
import axiosInstance from '../utils/axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Alert,
  Card,
  CardContent,
  TextField,
  Snackbar
} from '@mui/material';
import TestModeBanner from '../components/TestModeBanner';

const Recharge = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('card');
    const [currency, setCurrency] = useState('usd');
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        credits: 0
    });
    const [loading, setLoading] = useState(true);

    // Fixed package details
    const fixedPlan = { 
        priceId: "prod_RfzrewnzuaZyvD", 
        credits: 30, 
        amount: 1 
    };

    // Allow user to choose quantity (default is 1)
    const [quantity, setQuantity] = useState(1);

    const fetchUserData = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
            
            const response = await axiosInstance.get('/api/user/profile', config);
            if (response.data) {
                setUserData({
                    username: response.data.username ?? 'unknown',
                    email: response.data.email ?? 'unknown',
                    credits: response.data.credits ?? 0,
                });
            }
        } catch (error) {
            console.error('Error fetching user credits:', error);
            if (error.response?.status === 401) {
                localStorage.removeItem('token');
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const handlePaymentSuccess = () => {
        setPaymentSuccess(true);
        fetchUserData();
        setTimeout(() => setPaymentSuccess(false), 3000);
    };

    const handlePaymentMethodChange = (method, selectedCurrency) => {
        setPaymentMethod(method);
        setCurrency(selectedCurrency);
    };

    // 价格换算逻辑
    const getPrice = (basePrice) => {
        const rates = {
            usd: { symbol: '$', rate: 1 },
            eur: { symbol: '€', rate: 0.92 },
            cny: { symbol: '¥', rate: 7.2 }
        };
        const { symbol, rate } = rates[currency];
        return `${symbol}${(basePrice * rate).toFixed(2)}`;
    };

    // 添加单价格式化函数
    const formatUnitPrice = () => {
        const unitPrice = getPrice(1/30);
        return t('recharge.pricePerCredit', { price: unitPrice });
    };

    return (
        <Box sx={{ maxWidth: 800, mx: 'auto', p: 4 }}>
            {/* 页面标题和当前积分 */}
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h4" gutterBottom color="primary.light">
                    {t('recharge.title')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#f5f5dc' }}>
                        {userData.username}
                    </Typography>
                    <Typography variant="h6" sx={{ color: '#f5f5dc' }}>
                        {t('recharge.currentCredits')}: {userData.credits}
                    </Typography>
                </Box>
            </Box>

            <TestModeBanner />

            {/* 购买信息卡片 */}
            <Card sx={{ 
                mb: 4, 
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}>
                <CardContent>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                        <Typography variant="h5" color="primary">
                            {`${quantity * 30} ${t('common.credits')}`}
                        </Typography>
                        <Typography variant="h5" color="primary">
                            {getPrice(quantity)}
                        </Typography>
                    </Box>

                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        {formatUnitPrice()}
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
                        <Typography variant="subtitle1">
                            {t('recharge.quantityPrompt')}:
                        </Typography>
                        <TextField
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(parseInt(e.target.value) || 1)}
                            inputProps={{ min: 1 }}
                            size="small"
                            sx={{ width: 100 }}
                        />
                    </Box>
                </CardContent>
            </Card>

            {/* 支付表单卡片 */}
            <Card sx={{ 
                p: 3, 
                bgcolor: 'rgba(255, 255, 255, 0.9)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
            }}>
                <StripePaymentForm
                    priceId={fixedPlan.priceId}
                    quantity={quantity}
                    onSuccess={handlePaymentSuccess}
                    onPaymentMethodChange={handlePaymentMethodChange}
                    currency={currency}
                />
            </Card>

            {/* 支付方式说明 */}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <Typography 
                    variant="body2" 
                    sx={{ 
                        color: '#f5f5dc',
                        opacity: 0.8,
                        maxWidth: 600,
                        mx: 'auto'
                    }}
                >
                    {t('payment.acceptedCards')}
                </Typography>
            </Box>

            {/* 支付成功提示 */}
            <Snackbar
                open={paymentSuccess}
                autoHideDuration={3000}
                onClose={() => setPaymentSuccess(false)}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    {t('recharge.success')}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Recharge;