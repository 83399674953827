import axios from 'axios';

// 根据环境配置基础URL
const getBaseURL = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:8000';  // 本地开发环境指向后端8000端口
  }
  return 'https://starfish-app-73rfk.ondigitalocean.app'; // 生产环境
}

const axiosInstance = axios.create({
  baseURL: getBaseURL(),
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true
});

// 添加请求拦截器，增加连接状态日志
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    console.log(`[API Request] ${config.method.toUpperCase()} ${config.baseURL}${config.url}`);
    console.log(`[Environment] ${process.env.NODE_ENV}`);
    console.log(`[Backend] Using ${config.baseURL}`);
    
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('[API Request Error]', error);
    return Promise.reject(error);
  }
);

// 添加响应拦截器，处理连接错误
axiosInstance.interceptors.response.use(
  (response) => {
    console.log(`[API Response] Status: ${response.status} from ${response.config.baseURL}`);
    return response;
  },
  (error) => {
    if (!error.response) {
      console.error('[Connection Error] Backend server is not reachable');
      // 可以在这里触发全局错误提示
    } else {
      console.error('[API Error]', {
        status: error.response.status,
        url: error.config.url,
        baseURL: error.config.baseURL
      });
    }
    return Promise.reject(error);
  }
);

// 添加健康检查方法
export const checkBackendHealth = async () => {
  try {
    const response = await axiosInstance.get('/health');
    return {
      isHealthy: response.status === 200,
      environment: process.env.NODE_ENV,
      baseURL: axiosInstance.defaults.baseURL
    };
  } catch (error) {
    return {
      isHealthy: false,
      environment: process.env.NODE_ENV,
      baseURL: axiosInstance.defaults.baseURL,
      error: error.message
    };
  }
};

export default axiosInstance;
