import React, { useState, useEffect } from 'react';
import { Radio, RadioGroup, FormControlLabel, Box, Typography, Alert, Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = (props) => {
  const { t } = useTranslation();
  const [currency, setCurrency] = useState("usd");
  const [email, setEmail] = useState("");
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.onPaymentMethodChange) {
      props.onPaymentMethodChange('card', currency);
    }
  }, [currency, props.onPaymentMethodChange]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    try {
      const response = await axiosInstance.post('/api/stripe/create-payment-intent', {
        price_id: props.priceId,
        quantity: props.quantity || 1,
        payment_method: 'card',
        currency: currency,
        email: email
      });
      const { clientSecret } = response.data;

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      });

      if (paymentResult.error) {
        setError(t('payment.cardError'));
      } else if (
        paymentResult.paymentIntent.status === 'succeeded' ||
        paymentResult.paymentIntent.status === 'processing'
      ) {
        props.onSuccess();
      }
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.response?.data?.detail || t('payment.error'));
    } finally {
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="subtitle1" 
          color="text.secondary"
          gutterBottom
          sx={{ 
            fontSize: '0.875rem',
            fontWeight: 500,
            letterSpacing: '0.01em'
          }}
        >
          {t('payment.selectCurrency')}
        </Typography>
        <RadioGroup
          row
          value={currency}
          onChange={(e) => setCurrency(e.target.value)}
          sx={{ 
            '& .MuiFormControlLabel-root': {
              mr: 4,
              '& .MuiTypography-root': {
                fontSize: '0.9rem',
                fontWeight: 500
              }
            }
          }}
        >
          <FormControlLabel value="usd" control={<Radio />} label="USD $" />
          <FormControlLabel value="eur" control={<Radio />} label="EUR €" />
          <FormControlLabel value="cny" control={<Radio />} label="CNY ¥" />
        </RadioGroup>
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="subtitle1" 
          color="text.secondary"
          gutterBottom
          sx={{ 
            fontSize: '0.875rem',
            fontWeight: 500,
            letterSpacing: '0.01em'
          }}
        >
          {t('payment.emailPrompt')}
        </Typography>
        <TextField 
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t('payment.emailPlaceholder')}
          size="small"
          sx={{
            '& .MuiOutlinedInput-root': {
              backgroundColor: '#fff'
            }
          }}
        />
      </Box>

      <Box sx={{ mb: 4 }}>
        <Typography 
          variant="subtitle1" 
          color="text.secondary"
          gutterBottom
          sx={{ 
            fontSize: '0.875rem',
            fontWeight: 500,
            letterSpacing: '0.01em'
          }}
        >
          {t('payment.cardDetails')}
        </Typography>
        <Box sx={{ 
          p: 2, 
          border: '1px solid #e2e8f0', 
          borderRadius: 1, 
          bgcolor: '#fff',
          '&:hover': {
            borderColor: '#cbd5e1'
          }
        }}>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '14px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
              },
            }}
          />
        </Box>
      </Box>

      {error && (
        <Alert 
          severity="error" 
          sx={{ 
            mb: 3,
            '& .MuiAlert-message': {
              fontSize: '0.875rem'
            }
          }}
        >
          {error}
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={processing}
        fullWidth
        sx={{ 
          py: 1.25,
          fontSize: '0.95rem',
          fontWeight: 600,
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none'
          }
        }}
      >
        {processing ? t('payment.processing') : t('payment.payNow')}
      </Button>
    </form>
  );
};

const StripePaymentForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  );
};

export default StripePaymentForm; 