import React, { useState } from 'react';
import { 
  Container, 
  Typography, 
  Paper, 
  Box, 
  Card, 
  CardContent, 
  Grid,
  Chip,
  Link,
  Divider,
  Button
} from '@mui/material';
import { 
  Science as ScienceIcon,
  Psychology as PsychologyIcon,
  Timeline as TimelineIcon,
  School as SchoolIcon,
  Article as ArticleIcon,
  Person as PersonIcon,
  ThumbUp as ThumbUpIcon,
  BarChart as BarChartIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// Define a style for Paper components inspired by Apple's minimal design
const applePaperStyle = {
  padding: '24px',
  borderRadius: '12px',
  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
  backgroundColor: '#ffffff'
};

// Define a style for headings to use sentence case and the system font
const appleHeadingStyle = {
  textTransform: 'none',  // Do not force uppercase; only the first letter should appear capitalized naturally
  fontFamily: '"San Francisco", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif'
};

// Add these style definitions at the top
const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '40px 24px'
  },
  header: {
    marginBottom: '48px'
  },
  title: {
    fontSize: { xs: '32px', md: '48px' },
    fontWeight: '400',
    letterSpacing: '-0.02em',
    marginBottom: '16px',
    fontFamily: '"SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
  },
  nav: {
    display: 'flex',
    gap: { xs: '16px', md: '32px' },
    marginBottom: '48px',
    borderBottom: '1px solid #e5e7eb',
    paddingBottom: '16px',
    flexWrap: 'wrap'
  },
  navItem: {
    color: '#6b7280',
    fontSize: { xs: '14px', md: '16px' },
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: '#111827'
    },
    '&.active': {
      color: '#111827',
      fontWeight: '500'
    }
  },
  paperCard: {
    padding: '32px',
    marginBottom: '24px',
    borderRadius: '12px',
    border: '1px solid #e5e7eb',
    boxShadow: 'none',
    transition: 'all 0.2s ease',
    '&:hover': {
      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
      borderColor: '#d1d5db'
    }
  },
  paperMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '16px',
    color: '#6b7280',
    fontSize: '14px'
  },
  paperDate: {
    color: '#6b7280',
    fontSize: '14px'
  },
  paperAuthors: {
    color: '#4b5563',
    fontSize: '14px',
    fontWeight: '500'
  },
  paperStatus: {
    backgroundColor: '#f3f4f6',
    color: '#374151',
    padding: '4px 8px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500'
  },
  paperTitle: {
    fontSize: '24px',
    fontWeight: '500',
    marginBottom: '12px',
    color: '#111827',
    lineHeight: 1.3
  },
  paperDescription: {
    color: '#4b5563',
    fontSize: '16px',
    lineHeight: 1.6,
    marginBottom: '24px'
  },
  tag: {
    backgroundColor: '#f3f4f6',
    color: '#374151',
    border: 'none',
    borderRadius: '6px',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#e5e7eb'
    }
  }
};

const Research = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('all');

  const researchPapers = [
    {
      title: "Step Further Towards Automated Social Science: An AI-Powered Interview Platform",
      authors: "Fengming Liu, Shubin Yu",
      date: "2025",
      description: "This study introduces MimiTalk, an AI-powered automated interview platform capable of conducting in-depth interviews autonomously while maintaining high data quality standards. Through evaluation involving 20 participants, we demonstrate significant improvements in interview efficiency while maintaining response quality comparable to traditional methods. Results show an average interview duration of 141.632 seconds with strong semantic coherence and comprehensive topic coverage.",
      tags: ["AI Interview", "Platform Development", "Evaluation Study"],
      link: "https://ssrn.com/abstract=5120349",
      status: "Preprint at SSRN"
    },

    {
      title: "Efficacy of Large Language Models in Short-Text Topic Modeling",
      authors: "Shubin Yu",
      date: "2024",
      description: "This study explores the efficacy of large language models (LLMs) in short-text topic modeling, comparing their performance with human evaluation and Latent Dirichlet Allocation (LDA). In Study 1, we analyzed a dataset on chatbot anthropomorphism using human evaluation, LDA, and two LLMs (GPT-4 and Claude). Results showed that LLMs produced topic classifications similar to human analysis, outperforming LDA for short texts. In Study 2, we investigated the impact of sample size and LLM choice on topic modeling consistency using a COVID-19 vaccine hesitancy dataset. Findings revealed high consistency (80-90%) across various sample sizes, with even a 5% sample achieving 90% consistency. Comparison of three LLMs (Gemini Pro 1.5, GPT-4o, and Claude 3.5 Sonnet) showed comparable performance, with two models achieving 90% consistency. This research demonstrates that LLMs can effectively perform short-text topic modeling in medical informatics, offering a promising alternative to traditional methods. The high consistency with small sample sizes suggests potential for improved efficiency in research. However, variations in performance highlight the importance of model selection and the need for human supervision in topic modeling tasks.",
      tags: ["LLM", "Topic Modeling", "Medical Informatics"],
      link: "https://osf.io/preprints/osf/mqk3r",
      status: "Preprint at OSF"
    },
    {
      title: "Generative AI Policies in Academic Research: A Global Analysis of Top Universities",
      authors: "Renbin Song, Shubin Yu",
      date: "2025",
      description: "This study systematically analyzes Generative AI policies from 61 top global universities using large language models and manual validation. The research identifies 10 key policy themes including data privacy, transparency, and academic integrity, while revealing gaps in environmental sustainability and regular policy reviews. The findings provide a framework for responsible AI use in research and offer empirical evidence for policy standardization.",
      tags: ["AI Policy", "Academic Research", "Institutional Guidelines"],
      link: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=5139585",
      status: "Preprint at SSRN"
    },
    {
      title: "The Comparison of AI Interviewers and Human Interviewers: A Large Scale Field Experiment",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "This study investigates the comparative effectiveness of AI and human interviewers through the development and deployment of two AI interview platforms—MimiTalk (online) and MimiCall (telephone-based). Drawing from Social Desirability Bias Theory, Emotional Contagion Theory, and Information Theory, we examine differences in questioning strategies, response patterns, and information quality.",
      tags: ["Comparative Study", "Field Experiment", "Interview Methods"],
      link: "#",
      status: "In preparation"
    },
    {
      title: "Comparative Analysis of Multi-Modal AI Interview Systems",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "A systematic comparison of different modalities in AI-driven interview systems, evaluating four distinct configurations: text-to-text, voice-to-text, video-to-text, and voice-to-voice interactions. The study assesses these modalities across key metrics including response quality, participant comfort, information richness, and technical reliability.",
      tags: ["Multi-Modal Analysis", "System Evaluation", "User Experience"],
      link: "#",
      status: "In preparation"
    },
    {
      title: "Qualitative Researchers' Perspectives on AI Interview Platforms",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "An investigation into how qualitative researchers perceive and evaluate AI-powered interview platforms, examining opportunities and challenges in academic research contexts.",
      tags: ["User Study", "Research Methods", "Professional Perspectives"],
      link: "#",
      status: "Ongoing Research"
    },
    {
      title: "AI Interviewers in Cross-Cultural Research Contexts",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "Exploring the effectiveness and adaptability of AI interviewers in cross-cultural research settings, examining cultural sensitivity and communication patterns.",
      tags: ["Cross-Cultural Research", "AI Adaptation", "Cultural Sensitivity"],
      link: "#",
      status: "Ongoing Research"
    },
    {
      title: "Applications of AI Interviewers in Educational Research",
      authors: "MimiTalk Research Team",
      date: "2025",
      description: "A conceptual paper examining the potential applications and implications of AI interview systems in educational research and assessment.",
      tags: ["Education", "Conceptual Framework", "Research Applications"],
      link: "#",
      status: "Ongoing Research"
    }
  ];

  const externalResearch = [
    {
      title: "Conducting Qualitative Interviews with AI",
      authors: "Felix Chopra, Ingar Haaland",
      date: "2023",
      description: "The authors developed an AI interviewing system that leverages OpenAI's GPT-4 model with specialized prompting techniques. The system was designed to integrate seamlessly with standard survey software through a chat interface. The AI interviewer was programmed to follow structured topic guides while maintaining flexibility to ask probing questions based on participant responses. The platform incorporates features to maintain natural conversation flow and ensure consistent interview quality across large numbers of participants. In their evaluation, they conducted an extensive study with 395 interviews focusing on understanding stock market non-participation among US respondents. The study assessed interview quality through multiple measures including response length, content analysis, and participant satisfaction. Results showed impressive completion rates of 95% with interviews averaging 30 minutes in duration. The analysis demonstrated that this approach generated richer insights compared to traditional small-sample qualitative interviews. Notably, a majority of respondents (53%) indicated they would prefer an AI interviewer over a human interviewer for future studies.",
      tags: ["AI Interview", "GPT-4", "Survey Integration", "Qualitative Research"],
      link: "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4583756",
      status: "SSRN Working Paper"
    },
    {
      title: "AI Conversational Interviewing: Transforming Surveys with LLMs as Adaptive Interviewers",
      authors: "Alexander Wuttke, Matthias Aßenmacher, Christopher Klamm, Max M. Lang, Quirin Würschinger, Frauke Kreuter",
      date: "2024",
      description: "The research team developed a voice-assisted AI interviewing system powered by GPT-4. They created a user interface based on Chainlit that supports both audio input and output capabilities. The system incorporates carefully designed prompts derived from qualitative interview best practices. The platform was specifically designed to conduct semi-structured interviews on political topics while maintaining high standards of interview quality. The authors conducted a comparative study between AI and human interviews with university students, focusing on political topics. The evaluation employed both quantitative and qualitative measures to assess interview quality and effectiveness. Their findings showed that AI interviewers could achieve comparable interview quality to human interviewers, though some technical challenges emerged with the audio features. While demonstrating the general viability of AI interviewing, the study also identified specific areas for future improvement in the technology and implementation.",
      tags: ["Voice AI", "Political Research", "Comparative Study"],
      link: "https://arxiv.org/abs/2410.01824",
      status: "Arxiv Preprint"
    }

  ];

  const researchHighlights = [
    {
      title: "User-Friendly Platform",
      description: "Intuitive navigation for both researchers and interviewees, ensuring a smooth interview experience",
      icon: <PersonIcon fontSize="large" color="primary" />
    },
    {
      title: "High User Satisfaction",
      description: "Interviewees report positive experiences with the digital interview format, leading to more authentic responses",
      icon: <ThumbUpIcon fontSize="large" color="primary" />
    },
    {
      title: "Quality Results",
      description: "Interviews yield high semantic coherence, comprehensive insights, and diverse perspectives across participants",
      icon: <BarChartIcon fontSize="large" color="primary" />
    }
  ];

  // Filter papers based on active tab
  const getFilteredPapers = () => {
    switch(activeTab) {
      case 'mimitalk':
        return researchPapers;
      case 'external':
        return externalResearch;
      default: // 'all'
        return [...researchPapers, ...externalResearch];
    }
  };

  return (
    <Container sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="h1" sx={styles.title}>
          {t('research.title')}
        </Typography>
        <Box sx={styles.nav}>
          <Typography 
            sx={{...styles.navItem, 
              ...(activeTab === 'all' && {fontWeight: 500, color: '#111827'})
            }}
            onClick={() => setActiveTab('all')}
          >
            All
          </Typography>
          <Typography 
            sx={{...styles.navItem, 
              ...(activeTab === 'mimitalk' && {fontWeight: 500, color: '#111827'})
            }}
            onClick={() => setActiveTab('mimitalk')}
          >
            Research by MimiTalk
          </Typography>
          <Typography 
            sx={{...styles.navItem, 
              ...(activeTab === 'external' && {fontWeight: 500, color: '#111827'})
            }}
            onClick={() => setActiveTab('external')}
          >
            Research by other teams
          </Typography>
        </Box>
      </Box>

      {/* Show filtered papers based on active tab */}
      {getFilteredPapers().map((paper, index) => (
        <Paper key={index} sx={styles.paperCard}>
          <Box sx={styles.paperMeta}>
            <Typography sx={styles.paperDate}>
              {paper.date}
            </Typography>
            <Typography sx={styles.paperAuthors}>
              {paper.authors}
            </Typography>
            <Typography sx={styles.paperStatus}>
              {paper.status}
            </Typography>
          </Box>
          <Link 
            href={paper.link}
            sx={{textDecoration: 'none'}}
          >
            <Typography sx={styles.paperTitle}>
              {paper.title}
            </Typography>
          </Link>
          <Typography sx={styles.paperDescription}>
            {paper.description}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {paper.tags.map((tag, tagIndex) => (
              <Chip 
                key={tagIndex} 
                label={tag} 
                sx={styles.tag}
              />
            ))}
          </Box>
        </Paper>
      ))}
    </Container>
  );
};

export default Research; 