import React, { useState } from 'react';
import { Paper, TextField, Button, Typography, Box, Link as MuiLink, Container } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../utils/axios';

const Login = () => {
  const { t } = useTranslation();
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new URLSearchParams();
      formData.append('username', credentials.username);
      formData.append('password', credentials.password);

      const response = await axiosInstance.post('/api/login', formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log('Login response:', response.data);
      if (response.data.access_token) {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('username', credentials.username);
        try {
          await axiosInstance.get('/api/debug-token');
          console.log('Token verification successful');
          navigate('/projects'); // 修改这里：导航到 projects 页面
        } catch (error) {
          console.error('Token verification failed:', error);
          alert('Login successful but token verification failed. Please try again.');
        }
      }
    } catch (error) {
      console.error('Login error:', error.response?.data || error);
      alert(error.response?.data?.detail || 'Login failed, please try again');
    }
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Paper 
        sx={{ 
          p: 4,
          bgcolor: 'rgba(255,255,255,0.1)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t('auth.actions.login')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label={t('auth.fields.username')}
            value={credentials.username}
            onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            label={t('auth.fields.password')}
            type="password"
            value={credentials.password}
            onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
          />
          <Button 
            type="submit" 
            fullWidth 
            variant="contained" 
            sx={{ mt: 3, mb: 2 }}
          >
            {t('auth.actions.login')}
          </Button>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
            <MuiLink 
              component={Link} 
              to="/forgot-password"
              underline="hover"
            >
              {t('auth.actions.forgotPassword')}
            </MuiLink>
            <MuiLink 
              component={Link} 
              to="/forgot-username"
              underline="hover"
            >
              {t('auth.actions.forgotUsername')}
            </MuiLink>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;