import { useEffect } from 'react';

const ConcordChat = () => {
  useEffect(() => {
    // Initialize Concord when component mounts
    if (window.Concord) {
      window.Concord.init({
        siteUuid: '1bcbff5f-c49c-4b5c-9ecd-f159248ebf9e',
        // Add any custom configuration here
        settings: {
          position: 'bottom-right',
          primaryColor: '#2563eb',
          showAvatars: true
        }
      });
    }

    // Cleanup when component unmounts
    return () => {
      if (window.Concord && window.Concord.destroy) {
        window.Concord.destroy();
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

export default ConcordChat; 