import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../utils/axios';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Checkbox,
  useTheme,
  Card,
  FormControlLabel,
} from '@mui/material';
import {
  Mic,
  Stop,
  VideoCall,
  VideocamOff,
  Send,
  StopCircle,
  Language,
} from '@mui/icons-material';
import axios from '../utils/axios';
import { useTranslation } from 'react-i18next';

// Add styles at the top of the file
const typingAnimation = `
  @keyframes blink {
    0% { opacity: .2; }
    20% { opacity: 1; }
    100% { opacity: .2; }
  }
`;

const Interview = () => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { shareUrl } = useParams();
    const mediaRecorderRef = useRef(null);
    const fullVideoChunks = useRef([]);
    const audioChunksRef = useRef([]);
  const [showLanguageDialog, setShowLanguageDialog] = useState(true);
  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setShowLanguageDialog(false);
    setShowInfoDialog(true);
  };


  // Basic state variables
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recordAudio, setRecordAudio] = useState(true);
  const [recordVideo, setRecordVideo] = useState(false);
  const [showTimeUpDialog, setShowTimeUpDialog] = useState(false);

  // Interview state variables
  const [interviewStatus, setInterviewStatus] = useState('initial'); // Possible values: 'init', 'recording', 'answering', 'ended'
  const [messages, setMessages] = useState([]);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [interviewUuid, setInterviewUuid] = useState(null);
  const [isConnectionLost, setIsConnectionLost] = useState(false);
  const retryTimeoutRef = useRef(null);

  // Media-related state variables
  const [videoEnabled, setVideoEnabled] = useState(false); // Video preview is disabled by default
  const [currentRecording, setCurrentRecording] = useState(null); // Holds the recording stream

  // Refs for various purposes
  const videoRef = useRef(null); // Reference to the video element for preview
  const videoStreamRef = useRef(null); // Holds the video preview stream
  const wsRef = useRef(null); // Holds the WebSocket connection
  const timerRef = useRef(null); // Holds the timer interval
  const currentAnswerChunks = useRef([]); // Holds the chunks of the current answer audio
  const messagesContainerRef = useRef(null); // Reference to the messages container for auto-scrolling

  // Interviewee info form state
  const [intervieweeInfo, setIntervieweeInfo] = useState({
    name: '',
    age: '',
    gender: '',
    occupation: '',
    education: '',
    contact: '',
    notes: '',
  });
  const [showInfoDialog, setShowInfoDialog] = useState(false); // Controls the visibility of the info dialog
  const [isSubmitting, setIsSubmitting] = useState(false); // Indicates if the form is being submitted

  // Add new state for typing indicator
  const [isAITyping, setIsAITyping] = useState(false);

  // Add new state for transcription
  const [currentTranscription, setCurrentTranscription] = useState('');
  const [isTranscribing, setIsTranscribing] = useState(false);

  // Add new state for voice recording indicator
  const [isRecordingVoice, setIsRecordingVoice] = useState(false);

  // Add new state for consent
  const [consent, setConsent] = useState(false);

  // Function to toggle the video preview on and off
  const handleToggleVideo = () => {
    setVideoEnabled((prev) => !prev);
  };

  // Function to stop video recording entirely
  const stopVideoRecording = () => {
    stopAllMediaStreams(); // Stops all media streams
    setVideoEnabled(false); // Disables video preview
  };

  // Fetch the interview project when the component mounts or when 'shareUrl' changes
  useEffect(() => {
    fetchProjectByShareUrl();
    return () => cleanup();
  }, [shareUrl,t]);

  // Scroll to the bottom of the messages container when messages change
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // Handle changes in 'videoEnabled' state
  useEffect(() => {
    const updateVideoStreams = async () => {
      if (interviewStatus === 'recording' || interviewStatus === 'answering') {
        // If an interview is ongoing, reinitialize media streams to reflect the change in 'videoEnabled'
        await reinitializeMediaStreams();
      } else {
        // If no interview is ongoing, just start or stop the preview video
        if (videoEnabled) {
          await startPreviewVideo();
        } else {
          stopPreviewVideo();
        }
      }
    };

    updateVideoStreams();

    return () => {
      // Cleanup the preview video when the component unmounts or 'videoEnabled' changes
      stopPreviewVideo();
    };
  }, [videoEnabled]);

  // Function to clean up resources when the component unmounts
  const cleanup = () => {
    if (timerRef.current) clearInterval(timerRef.current);
    if (wsRef.current) wsRef.current.close();
    stopAllMediaStreams();
  };

  // Function to fetch the interview project using the 'shareUrl'
  const fetchProjectByShareUrl = async () => {
    try {
      const response = await axios.get(`/api/interview/${shareUrl}`);
      console.log('Fetched project data:', response.data);
      console.log('Max duration from project:', response.data.max_duration, 'minutes');
      setProject(response.data);
      setMessages([
        {
          role: 'ai',
          content: t('interview.messages.welcome'),
          timestamp: new Date().toISOString(),
        },
      ]);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch project:', error);
      setMessages((prev) => [
        ...prev,
        {
          role: 'ai',
          content: t('interview.messages.loadError'),
          timestamp: new Date().toISOString(),
        },
      ]);
      alert(
        error.response?.data?.detail ||
        t('interview.messages.loadFailed')
      );
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the submission of the interviewee info form
  const handleInfoSubmit = async () => {
    if (isSubmitting) return;

    try {
        setIsSubmitting(true);
        const formData = {
            ...intervieweeInfo,
            age: intervieweeInfo.age ? parseInt(intervieweeInfo.age) : undefined,
        };
        const response = await axios.post(`/api/interview/start/${shareUrl}`, formData);
        const uuid = response.data.interview_uuid;
        if (!uuid || typeof uuid !== 'string') {
            throw new Error('Invalid interview UUID received from server');
        }
        setInterviewUuid(uuid);
        setShowInfoDialog(false);
        await initializeInterview(uuid);
        setInterviewStatus('recording');
    } catch (error) {
        console.error('Error in handleInfoSubmit:', error);
        if (error.response?.status === 403 && error.response?.data?.detail?.includes('Insufficient credits')) {
            setMessages([{
                role: 'ai',
                content: t('interview.errors.insufficientCredits', {
                    defaultValue: "We apologize, but this interview cannot proceed due to insufficient credits. Please contact the interviewer to top up credits before continuing. Thank you for your understanding."
                }),
                timestamp: new Date().toISOString()
            }]);
            setShowInfoDialog(false);
        } else {
            alert(t('interview.startFailed') || 'Failed to start the interview.');
        }
    } finally {
        setIsSubmitting(false);
    }
  };

  // Function to handle skipping the interviewee info form
  const handleSkipForm = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`/api/interview/start/${shareUrl}`, {});
      const uuid = response.data.interview_uuid;
      if (!uuid || typeof uuid !== 'string') {
        throw new Error('Invalid interview UUID received from server');
      }
      setInterviewUuid(uuid);
      setShowInfoDialog(false);
      await initializeInterview(uuid);
      setInterviewStatus('recording');
    } catch (error) {
      console.error('Error skipping form:', error);
      alert(
        'Failed to start interview: ' + (error.response?.data?.detail || error.message)
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to initialize the interview
  const initializeInterview = async (uuid) => {
    try {
      if (!uuid) {
        throw new Error('Invalid interview UUID');
      }
      await setupMediaStreams(); // Set up the necessary media streams
      await setupWebSocketConnection(uuid); // Set up the WebSocket connection
      startTimer(); // Start the interview timer
      
      // Only start video recording if it's enabled in the project
      if (project?.record_video) {
        await startFullVideoRecording();
      }
      
      setInterviewStatus('recording');
    } catch (error) {
      console.error('Error initializing interview:', error);
      cleanup();
      alert(t('interview.errors.initError'));
    }
  };

  // Function to set up the WebSocket connection
  const setupWebSocketConnection = async (uuid) => {
    return new Promise((resolve, reject) => {
      try {
        if (!uuid || typeof uuid !== 'string') {
          throw new Error('Invalid interview UUID');
        }
        const wsUrl = `wss://starfish-app-73rfk.ondigitalocean.app/api/ws/interview/${uuid}`;
        wsRef.current = new WebSocket(wsUrl);
        wsRef.current.onopen = () => {
          setIsConnectionLost(false);
          resolve();
        };
        wsRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
          reject(error);
        };
        wsRef.current.onclose = (event) => {
          handleWebSocketClose(event);
        };
        wsRef.current.onmessage = (event) => {
          handleWebSocketMessage(event);
        };
      } catch (error) {
        reject(error);
      }
    });
  };

  // Function to handle incoming WebSocket messages
  const handleWebSocketMessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'end_interview') {
        endInterview();
      } else if (data.type === 'typing') {
        setIsAITyping(true);
      } else if (data.type === 'transcription') {
        setCurrentTranscription(data.text);
        setIsTranscribing(true);
      } else if (data.type === 'transcription_final') {
        const userEntry = {
          role: 'user',
          content: data.text,
          timestamp: data.timestamp // Use backend-provided timestamp
        };
        setMessages(prev => [...prev, userEntry]);
        setConversationHistory(prev => [...prev, userEntry]);
        setIsTranscribing(false);
        setCurrentTranscription('');
      } else if (data.type === 'ai_response') {
        setIsAITyping(false);
        setIsTranscribing(false);
        const newEntry = {
            role: 'assistant',
            content: data.message,
            timestamp: data.timestamp || new Date().toISOString()
        };
        setMessages(prev => [...prev, newEntry]);
        setConversationHistory(prev => [...prev, newEntry]);
      }
    } catch (error) {
      console.error('Error handling WebSocket message:', error);
    }
  };

  // Function to handle WebSocket closure
  const handleWebSocketClose = (event) => {
    if (interviewStatus === 'recording') {
      alert('Interview connection lost, please refresh the page and try again');
      cleanup();
    }
  };

  // Function to start the video preview
  const startPreviewVideo = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      videoStreamRef.current = stream;
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      console.error('Error accessing the camera:', error);
      alert('Unable to access the camera. Please check permissions.');
    }
  };

  // Function to stop the video preview
  const stopPreviewVideo = () => {
    if (videoStreamRef.current) {
      videoStreamRef.current.getTracks().forEach((track) => track.stop());
      videoStreamRef.current = null;
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  // Function to reinitialize media streams during an ongoing interview when 'videoEnabled' changes
  const reinitializeMediaStreams = async () => {
    try {
      // Stop current recording
      if (currentRecording) {
        currentRecording.getTracks().forEach((track) => track.stop());
        setCurrentRecording(null);
      }
      // Stop preview video
      stopPreviewVideo();

      // Setup new media streams based on 'videoEnabled' state
      await setupMediaStreams();
      // Restart full video recording with new constraints
      await startFullVideoRecording();
    } catch (error) {
      console.error('Error reinitializing media streams:', error);
    }
  };

  // Function to set up media streams for recording
  const setupMediaStreams = async () => {
    try {
        const constraints = {
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
            }
        };

        // Only add video constraints if video is explicitly enabled
        if (project?.record_video && videoEnabled) {
            constraints.video = { width: 1280, height: 720 };
        }

        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        // Handle video preview if video is enabled
        if (videoRef.current && project?.record_video && videoEnabled) {
            videoRef.current.srcObject = stream;
        } else if (videoRef.current) {
            videoRef.current.srcObject = null;
        }

        return stream;
    } catch (error) {
        console.error('Error setting up media streams:', error);
        if (error.name === 'NotAllowedError') {
            alert(t('interview.errors.permissionDenied'));
        } else {
            alert(t('interview.errors.deviceError'));
        }
        throw error;
    }
  };

  // Function to start recording the full interview
  const startFullVideoRecording = async () => {
    try {
        const stream = await setupMediaStreams();
        
        // Only start recording if we have permissions
        if (stream) {
            const recorder = new MediaRecorder(stream, {
                mimeType: 'video/webm',
            });

            recorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    fullVideoChunks.current.push(event.data);
                }
            };

            recorder.start(1000); // Start recording in chunks of 1 second
            setCurrentRecording(stream);
        }
    } catch (error) {
        console.error('Error starting recording:', error);
        // Don't show the permissions error if video isn't required
        if (project?.record_video) {
            alert(t('interview.errors.recordingError'));
        }
    }
  };

  // Function to start recording an answer
  const startAnswering = async () => {
    try {
      if (interviewStatus !== 'recording') return;
      setIsRecordingVoice(true);
      
      // Detect iOS device
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      console.log(`Device detection: iOS=${isIOS}`);
      
      // Set audio constraints based on device
      const audioConstraints = {
        echoCancellation: true,
        noiseSuppression: true
      };
      
      // iOS devices need specific constraints
      if (isIOS) {
        // iOS works best with these settings
        audioConstraints.sampleRate = 44100;
        audioConstraints.channelCount = 1;
      } else {
        // Other devices can use higher quality
        audioConstraints.sampleRate = 48000;
        audioConstraints.channelCount = 1;
      }
      
      console.log('Using audio constraints:', JSON.stringify(audioConstraints));
      
      // Request audio stream with appropriate constraints
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: audioConstraints
      });

      // Choose appropriate MIME type
      // iOS Safari supports these formats
      const supportedMimeTypes = [
        'audio/mp4',
        'audio/aac',
        'audio/webm',
        'audio/ogg',
        'audio/wav',
        'audio/mp3'
      ];
      
      // Find the first supported MIME type
      let selectedMimeType = 'audio/mp3'; // Default fallback
      for (const mimeType of supportedMimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          selectedMimeType = mimeType;
          console.log(`Found supported MIME type: ${mimeType}`);
          break;
        }
      }
      
      const options = {
        mimeType: selectedMimeType,
        audioBitsPerSecond: 128000
      };
      
      // Log the options we're using
      console.log('Creating MediaRecorder with options:', JSON.stringify(options));
      
      try {
        // Create media recorder with selected options
        const mediaRecorder = new MediaRecorder(stream, options);
        mediaRecorderRef.current = mediaRecorder;
        currentAnswerChunks.current = [];
        
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            currentAnswerChunks.current.push(event.data);
            console.log('Received audio chunk, size:', event.data.size, 'type:', event.data.type);
          }
        };
        
        // Start recording with smaller time slices for better chunk handling
        mediaRecorder.start(500); // 500ms chunks
        console.log(`Started recording audio with MIME type: ${mediaRecorder.mimeType}`);
        
        setInterviewStatus('answering');
      } catch (recorderError) {
        console.error('Error creating MediaRecorder:', recorderError);
        // If we couldn't create the MediaRecorder with specific options, try with default
        const basicRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = basicRecorder;
        currentAnswerChunks.current = [];
        
        basicRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            currentAnswerChunks.current.push(event.data);
            console.log('Received audio chunk (fallback), size:', event.data.size);
          }
        };
        
        basicRecorder.start(500);
        console.log('Started recording with fallback MediaRecorder');
        setInterviewStatus('answering');
      }
    } catch (error) {
      console.error('Error starting answer:', error);
      setIsRecordingVoice(false);
      alert(t('interview.errors.microphoneError', {
        defaultValue: 'Could not access microphone. Please check your device permissions.'
      }));
    }
  };

  // Function to end recording an answer
  const endAnswer = async () => {
    try {
      setIsRecordingVoice(false);
      if (interviewStatus !== 'answering') return;

      if (!mediaRecorderRef.current) return;

      const mediaRecorder = mediaRecorderRef.current;
      
      console.log('Stopping recording...');
      mediaRecorder.onstop = async () => {
        try {
          // Get the MIME type from the recorder or fallback
          const mimeType = mediaRecorder.mimeType || 'audio/webm';
          console.log('Creating blob with MIME type:', mimeType);
          
          // Create blob from chunks
          const blob = new Blob(currentAnswerChunks.current, { type: mimeType });
          console.log('Created audio blob, size:', blob.size, 'type:', blob.type);
          
          // Convert to base64
          const base64Audio = await blobToBase64(blob);
          console.log('Converted audio to base64, length:', base64Audio.length);

          // Detect iOS device
          const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          
          // Send everything to the server
          wsRef.current.send(
            JSON.stringify({
              type: 'end_answer',
              audio: base64Audio,
              mime_type: blob.type,
              is_ios: isIOS,
              timestamp: new Date().toISOString(),
            })
          );
          console.log('Sent audio data through WebSocket');

          setInterviewStatus('recording');
          currentAnswerChunks.current = [];
        } catch (error) {
          console.error('Error processing audio:', error);
          alert(t('interview.errors.audioProcessingError', {
            defaultValue: 'Error processing audio. Please try again.'
          }));
        }
      };

      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
      console.log('Stopped recording and released media stream');

    } catch (error) {
      console.error('Error ending answer:', error);
      setInterviewStatus('recording');
    }
  };

  // Function to end the interview
  const endInterview = async () => {
    try {
      console.log('Ending interview. Status:', interviewStatus);
      if (!interviewUuid) {
        console.error('No valid interview UUID found');
        return;
      }

      // Format the date in UTC ISO format without timezone
      const now = new Date();
      const utcDate = new Date(Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      ));
      const isoDate = utcDate.toISOString();

      // Clear the timer
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }

      const saveData = {
        conversation_history: conversationHistory.map(entry => ({
            ...entry,
            timestamp: entry.timestamp // Keep original timestamp
        })),
        ended_at: new Date().toISOString()
      };

      console.log('Sending save request with data:', saveData);

      const response = await axiosInstance.post(
        `/api/interviews/${interviewUuid}/save`,
        saveData
      );

      if (response.data.credits_remaining < 0) {
        alert('Warning: You have insufficient credits');
      }

      setInterviewStatus('ended');
      cleanup();
    } catch (error) {
      console.error('Error ending interview:', error);
      if (error.response) {
        console.error('Error response:', {
          status: error.response.status,
          data: error.response.data,
          headers: error.response.headers
        });
      }
      if (error.response?.data?.detail) {
        alert('An error occurred: ' + error.response.data.detail);
      } else {
        alert('An error occurred when ending the interview: ' + error.message);
      }
    }
  };

  // Helper function to convert a Blob to a Base64 string
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // Function to start the interview timer
  const startTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    console.log('Starting timer. Project max duration:', project?.max_duration, 'minutes');
    
    timerRef.current = setInterval(() => {
      setElapsedTime((prev) => {
        const maxSeconds = (project?.max_duration || 60) * 60;
        console.log('Timer check - Current time:', prev, 'seconds, Max time:', maxSeconds, 'seconds');
        if (prev >= maxSeconds) {
          console.log('Time limit reached! Stopping interview...');
          clearInterval(timerRef.current);
          setShowTimeUpDialog(true);
          endInterview();
          return prev;
        }
        return prev + 1;
      });
    }, 1000);
  };

  // Helper function to format elapsed time in MM:SS format
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  // Function to stop all media streams
  const stopAllMediaStreams = () => {
    // Stop the preview video stream
    if (videoStreamRef.current) {
      videoStreamRef.current.getTracks().forEach((track) => track.stop());
      videoStreamRef.current = null;
    }

    // Stop the recording video stream
    if (currentRecording) {
      currentRecording.getTracks().forEach((track) => track.stop());
      setCurrentRecording(null);
    }

    // Stop audio recording stream
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      mediaRecorderRef.current = null;
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  };

  // Function to handle starting or ending the interview
  const handleStartOrEndInterview = () => {
    if (interviewStatus === 'recording' || interviewStatus === 'answering') {
      endInterview();
    } else if (interviewStatus === 'init') {
      if (!interviewUuid) {
        setShowLanguageDialog(true);
      }
    }
  };

  // Function to handle form submission
  const handleSubmitInfo = async () => {
    try {
      setIsSubmitting(true);
      console.log('Submitting info, current language:', i18n.language);
      const response = await axios.post(`/api/interviews/${interviewUuid}/info`, intervieweeInfo);
      
      if (response.status === 200) {
        setShowInfoDialog(false);
        setInterviewStatus('recording');
        
        setMessages(prev => [...prev, {
          role: 'ai',
          content: t('interview.messages.welcome2'),
          timestamp: new Date().toISOString(),
        }]);
        setConversationHistory([{
          role: 'assistant',
          content: t('interview.messages.welcome2'),
          timestamp: new Date().toISOString(),
        }]);
        
        startTimer();
      }
    } catch (error) {
      console.error('Error submitting interviewee info:', error);
      alert(t('interview.errors.submitInfo'));
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add warning when approaching time limit
  useEffect(() => {
    if (project?.max_duration && elapsedTime === (project.max_duration - 1) * 60) {
      console.log('Warning: approaching time limit');
      alert(t('interview.warnings.timeRunningOut', { minutes: 1 }));
    }
  }, [elapsedTime, project?.max_duration]);

  // Clean up timer on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  // Add a useEffect to monitor elapsed time
  useEffect(() => {
    const maxSeconds = (project?.max_duration || 60) * 60;
    console.log('Time monitor - Current time:', elapsedTime, 'seconds, Max time:', maxSeconds, 'seconds');
    console.log('Current interview status:', interviewStatus);
    if (elapsedTime >= maxSeconds && interviewStatus !== 'ended') {
      console.log('Time limit reached:', elapsedTime, 'max:', maxSeconds);
      setShowTimeUpDialog(true);
      endInterview();
    }
  }, [elapsedTime, project?.max_duration, interviewStatus]);

  // Add useEffect to log project data when it changes
  useEffect(() => {
    if (project) {
      console.log('Project data updated:', {
        max_duration: project.max_duration,
        current_status: interviewStatus,
        elapsed_time: elapsedTime
      });
    }
  }, [project]);

  // UseEffect to add animation styles to the document
  useEffect(() => {
    // Add the animation styles to the document
    const style = document.createElement('style');
    style.textContent = `
      ${typingAnimation}
      .typing-dot {
        animation: blink 1.4s infinite;
        animation-fill-mode: both;
        font-size: 20px;
      }
      .typing-dot:nth-child(2) { animation-delay: .2s; }
      .typing-dot:nth-child(3) { animation-delay: .4s; }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  // Add back the transcribing indicator
  const renderMessages = () => {
    return messages.map((message, index) => (
      <Box
        key={index}
        sx={{
          mb: 2,
          p: 1,
          bgcolor: message.role === 'user' ? '#f0f7ff' : '#e3f2fd',
          borderRadius: 1,
          marginLeft: message.role === 'user' ? 'auto' : 0,
          marginRight: message.role === 'user' ? 0 : 'auto',
          maxWidth: '80%',
        }}
      >
        <Typography variant="subtitle2" color="text.secondary">
          {message.role === 'assistant' 
            ? t('interview.messages.aiInterviewer')
            : t('interview.messages.interviewee')}:
        </Typography>
        <Typography variant="body1">
          {message.content}
        </Typography>
        {message.timestamp && (
          <Typography variant="caption" display="block" sx={{ mt: 0.5, color: 'text.secondary' }}>
            {new Date(message.timestamp).toLocaleTimeString()}
          </Typography>
        )}
      </Box>
    ));
  };

  // Render the JSX
return (
    <Container maxWidth="lg">
        {/* Alert if the connection is lost */}
        {isConnectionLost && (
            <Alert severity="warning" sx={{ mb: 2 }}>
                {t('interview.status.connectionLost')}
            </Alert>
        )}

        {/* Microphone permission help */}
        <Alert 
            severity="info" 
            sx={{ mb: 2 }}
        >
            <Typography variant="body2">
                {t('interview.help.microphonePermission', {
                    defaultValue: "Having trouble starting the interview? Check if microphone permission is enabled. Look for the icon near the website URL in your browser and click it to grant permission."
                })}
            </Typography>
        </Alert>

        {/* Recording mode warning */}
        <Alert 
            severity="info" 
            sx={{ mb: 2 }}
        >
            {project?.record_audio 
                ? t('interview.warnings.audioRecorded')
                : t('interview.warnings.textOnly')
            }
        </Alert>
        
        {/* Interview language info */}
        <Alert 
            severity="info" 
            sx={{ mb: 2 }}
        >
            {t('interview.info.language', { 
                language: t(`projects.languages.${project?.interview_language?.toLowerCase() || 'english'}`)
            })}
        </Alert>

        <Dialog
            open={showLanguageDialog}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                Select your language / 选择语言 / Choisissez votre langue / Velg språk
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('en')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            English
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('zh')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            中文
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('fr')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            Français
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleLanguageChange('nb')}
                            sx={{ height: '100px', fontSize: '1.2rem' }}
                        >
                            Norsk
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>

        {/* Dialog for the interviewee info form */}
        <Dialog
            open={showInfoDialog}
            onClose={() => {
                if (!isSubmitting) setShowInfoDialog(false);
            }}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>{t('interview.title')}</DialogTitle>
            <DialogContent>
                <Box component="form" sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                        {/* Name Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.name')}
                                value={intervieweeInfo.name}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, name: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Age Field */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.age')}
                                type="number"
                                value={intervieweeInfo.age}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, age: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Gender Field */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>{t('interview.fields.gender')}</InputLabel>
                                <Select
                                    value={intervieweeInfo.gender}
                                    onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, gender: e.target.value }))}
                                >
                                    <MenuItem value="male">{t('interview.gender.male')}</MenuItem>
                                    <MenuItem value="female">{t('interview.gender.female')}</MenuItem>
                                    <MenuItem value="other">{t('interview.gender.other')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {/* Education Field */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.education')}
                                value={intervieweeInfo.education}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, education: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                        {/* Add consent checkbox before the notes field */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={consent}
                                        onChange={(e) => setConsent(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label={t('interview.consent.checkbox')}
                            />
                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                {t('interview.consent.description')}
                            </Typography>
                        </Grid>
                        {/* Notes Field */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('interview.fields.platformId')}
                                multiline
                                rows={4}
                                value={intervieweeInfo.notes}
                                onChange={(e) => setIntervieweeInfo(prev => ({ ...prev, notes: e.target.value }))}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button 
                    variant="contained" 
                    onClick={handleInfoSubmit} 
                    disabled={isSubmitting || !consent}
                    sx={{ minWidth: 120 }}
                >
                    {isSubmitting ? t('interview.controls.starting') : t('interview.controls.startInterview')}
                </Button>
            </DialogActions>
        </Dialog>
    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            {t('interview.note')}
    </Typography>
        {/* Main Content */}
        <Paper sx={{ p: 3, my: 4 }}>
            {/* Project Name */}
            <Typography variant="h4" gutterBottom>
                {project?.name || t('interview.title')}
            </Typography>

            {/* Control Buttons */}
            <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                <Button
                    variant="contained"
                    color={interviewStatus === 'recording' || interviewStatus === 'answering' ? 'error' : 'primary'}
                    startIcon={interviewStatus === 'recording' || interviewStatus === 'answering' ? <Stop /> : <Mic />}
                    onClick={handleStartOrEndInterview}
                    disabled={isSubmitting}
                >
                    {interviewStatus === 'recording' || interviewStatus === 'answering'
                        ? t('interview.controls.endInterview')
                        : t('interview.controls.startInterview')}
                </Button>
                {(interviewStatus === 'recording' || interviewStatus === 'answering') && (
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<Mic />}
                            onClick={startAnswering}
                            disabled={interviewStatus === 'answering'}
                        >
                            {interviewStatus === 'answering'
                                ? t('interview.controls.recording')
                                : t('interview.controls.startAnswer')}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            endIcon={<Send />}
                            onClick={endAnswer}
                            disabled={interviewStatus !== 'answering'}
                        >
                            {t('interview.controls.endAnswer')}
                        </Button>
                    </>
                )}
            </Box>

            {/* Interview Log */}
            <Box sx={{ mt: 3 }}>
                <Typography variant="h6" gutterBottom>
                    {t('interview.sections.interviewLog')}
                </Typography>
                <Card ref={messagesContainerRef} sx={{ p: 2, maxHeight: '300px', overflowY: 'auto', bgcolor: '#f5f5f5' }}>
                    {renderMessages()}
                    {isRecordingVoice && (
                        <Box
                            sx={{
                                mb: 2,
                                p: 1,
                                bgcolor: '#f0f7ff',
                                borderRadius: 1,
                                marginLeft: 'auto',
                                marginRight: 0,
                                maxWidth: '80%',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                            }}
                        >
                            <Typography variant="subtitle2" color="text.secondary">
                                {t('interview.messages.recording')}
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <span className="recording-dot" style={{ color: 'red' }}>●</span>
                            </Box>
                        </Box>
                    )}
                    {isAITyping && (
                        <Box
                            sx={{
                                mb: 2,
                                p: 1,
                                bgcolor: '#e3f2fd',
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1
                            }}
                        >
                            <Typography variant="subtitle2" color="text.secondary">
                                {t('interview.messages.aiInterviewer')}:
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                                <span className="typing-dot">•</span>
                                <span className="typing-dot">•</span>
                                <span className="typing-dot">•</span>
                            </Box>
                        </Box>
                    )}
                </Card>
            </Box>
        </Paper>

        {/* Elapsed Time Display */}
        <Box sx={{ position: 'fixed', top: 20, right: 20 }}>
            <Typography variant="h6">
                {t('interview.status.elapsedTime')}: {formatTime(elapsedTime)}
            </Typography>
        </Box>

        {/* Video Controls */}
        <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
            {/* Removed the video toggle and stop recording buttons */}
        </Box>

        {videoEnabled && (
            <video
                ref={videoRef}
                autoPlay
                muted
                playsInline
                style={{ width: '100%', maxWidth: '500px', marginTop: '20px' }}
            />
        )}

        {/* Time Up Dialog */}
        <Dialog
            open={showTimeUpDialog}
            onClose={() => setShowTimeUpDialog(false)}
        >
            <DialogTitle>
                {t('interview.timeUp.title')}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t('interview.timeUp.message', { duration: project?.max_duration || 60 })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button 
                    onClick={() => setShowTimeUpDialog(false)}
                    variant="contained"
                >
                    {t('common.buttons.OK')}
                </Button>
            </DialogActions>
        </Dialog>
    </Container>
);
};

export default Interview;
