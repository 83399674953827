import React, { useEffect } from 'react';
import { Typography, Grid, Box, Button, Card, CardContent, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { useNavigate } from 'react-router-dom';
import { motion, useScroll, useSpring } from 'framer-motion'; // 需要安装 framer-motion

// 动画配置
const fadeInUp = {
  initial: { opacity: 0, y: 60 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

// 进度条组件
const ProgressBar = () => {
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  return (
    <motion.div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        background: 'linear-gradient(to right, #6a8caf, #b4c7d9)',
        transformOrigin: '0%',
        scaleX,
        zIndex: 1000
      }}
    />
  );
};

const Section = ({ children, bgImage, overlay = true, sx = {}, ...props }) => (
  <Box
    sx={{
      minHeight: '100vh',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      backgroundImage: bgImage ? `url("${bgImage}")` : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      '&::before': overlay ? {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7), rgba(0,0,0,0.4))',
        backdropFilter: 'blur(8px)',
        zIndex: 1
      } : {},
      '& > *': {
        position: 'relative',
        zIndex: 2
      },
      ...sx
    }}
    {...props}
  >
    {children}
  </Box>
);

const FeatureCard = motion(Card);

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const features = [
    {
      icon: <PersonIcon sx={{ fontSize: 40, color: '#f5f5dc' }} />,
      title: t('home.features.aiPowered.title'),
      description: t('home.features.aiPowered.description')
    },
    {
      icon: <SettingsIcon sx={{ fontSize: 40, color: '#f5f5dc' }} />,
      title: t('home.features.customizable.title'),
      description: t('home.features.customizable.description')
    },
    {
      icon: <AnalyticsIcon sx={{ fontSize: 40, color: '#f5f5dc' }} />,
      title: t('home.features.downloadable.title'),
      description: t('home.features.downloadable.description')
    }
  ];

  return (
    <>
      <ProgressBar />
      <Box
        sx={{
          height: '100vh',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'auto',
          overflowX: 'hidden',
          scrollSnapType: 'y mandatory',
          scrollBehavior: 'smooth',
          backgroundColor: 'rgba(0,0,0,0.8)',
          '&::-webkit-scrollbar': {
            width: '8px',
            background: 'transparent'
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255,255,255,0.05)'
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255,255,255,0.2)',
            borderRadius: '4px',
            '&:hover': {
              background: 'rgba(255,255,255,0.3)'
            }
          }
        }}
      >
        {/* Hero Section with Parallax Effect */}
        <Section 
          bgImage="/image/hero-bg.png"
          sx={{
            scrollSnapAlign: 'start',
            scrollSnapStop: 'always',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            style={{ width: '100%' }}
          >
            <Box sx={{ 
              maxWidth: '1200px', 
              mx: 'auto', 
              p: 4,
              position: 'relative',
              zIndex: 2
            }}>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <Typography 
                  variant="h1"
                  sx={{
                    fontSize: { xs: '2.5rem', md: '4.5rem' },
                    fontWeight: 800,
                    color: '#fff',
                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                    lineHeight: 1.2
                  }}
                >
                  {t('home.hero.title')}
                </Typography>
              </motion.div>
              <Typography
                variant="h4"
                sx={{
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                  color: '#fff',
                  mb: 6,
                  maxWidth: '800px',
                  mx: 'auto'
                }}
              >
                {t('home.hero.subtitle')}
              </Typography>
              <Box sx={{ display: 'flex', gap: 3, justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => navigate('/register')}
                  sx={{
                    bgcolor: 'primary.main',
                    fontSize: '1.1rem',
                    py: 2,
                    px: 4
                  }}
                >
                  {t('home.hero.buttons.getStarted')}
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={() => navigate('/about')}
                  sx={{
                    borderColor: '#fff',
                    color: '#fff',
                    fontSize: '1.1rem',
                    py: 2,
                    px: 4,
                    '&:hover': {
                      borderColor: '#fff',
                      bgcolor: 'rgba(255,255,255,0.1)'
                    }
                  }}
                >
                  {t('home.hero.buttons.learnMore')}
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Section>

        {/* Features Section with Staggered Animation */}
        <Section 
          bgImage="/image/features-bg.png"
          sx={{
            scrollSnapAlign: 'start',
            scrollSnapStop: 'always'
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            style={{ width: '100%' }}
          >
            <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 4 }}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                  mb: 8,
                  color: '#fff',
                  fontSize: { xs: '2rem', md: '3rem' }
                }}
              >
                {t('home.features.title')}
              </Typography>
              <Grid container spacing={4}>
                {features.map((feature, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <FeatureCard
                      whileHover={{ 
                        scale: 1.05,
                        boxShadow: "0 8px 30px rgba(0,0,0,0.12)"
                      }}
                      transition={{ duration: 0.2 }}
                      sx={{
                        height: '100%',
                        bgcolor: 'rgba(255,255,255,0.1)',
                        backdropFilter: 'blur(10px)',
                        border: '1px solid rgba(255,255,255,0.1)'
                      }}
                    >
                      <CardContent sx={{ textAlign: 'center', p: 4 }}>
                        <Box sx={{ mb: 3 }}>{feature.icon}</Box>
                        <Typography
                          variant="h5"
                          component="h2"
                          gutterBottom
                          sx={{ fontWeight: 600, color: '#f5f5dc' }}
                        >
                          {feature.title}
                        </Typography>
                        <Typography sx={{ color: '#f5f5dc' }}>
                          {feature.description}
                        </Typography>
                      </CardContent>
                    </FeatureCard>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </motion.div>
        </Section>

        {/* How It Works Section with Interactive Cards */}
        <Section 
          bgImage="/image/process-bg.png"
          sx={{
            scrollSnapAlign: 'start',
            scrollSnapStop: 'always'
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            style={{ width: '100%' }}
          >
            <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 4 }}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                  mb: 8,
                  color: '#fff',
                  fontSize: { xs: '2rem', md: '3rem' }
                }}
              >
                {t('home.howItWorks.title')}
              </Typography>
              <Grid container spacing={6}>
                {['create', 'share', 'review'].map((step, index) => (
                  <Grid item xs={12} md={4} key={step}>
                    <motion.div
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.2 }}
                      viewport={{ once: true }}
                    >
                      <Box sx={{ textAlign: 'center' }}>
                        <Typography
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            mb: 2,
                            color: '#f5f5dc'
                          }}
                        >
                          {t(`home.howItWorks.steps.${step}.title`)}
                        </Typography>
                        <Typography sx={{ color: '#f5f5dc' }}>
                          {t(`home.howItWorks.steps.${step}.description`)}
                        </Typography>
                      </Box>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </motion.div>
        </Section>

        {/* GDPR Section with Fade Effect */}
        <Section 
          bgImage="/image/security-bg.png"
          sx={{
            scrollSnapAlign: 'start',
            scrollSnapStop: 'always'
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            style={{ width: '100%' }}
          >
            <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 4 }}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: 'center',
                  mb: 6,
                  color: '#fff',
                  fontSize: { xs: '2rem', md: '3rem' }
                }}
              >
                {t('home.gdpr.title')}
              </Typography>
              <Card
                sx={{
                  bgcolor: 'rgba(255,255,255,0.1)',
                  backdropFilter: 'blur(10px)',
                  p: 4
                }}
              >
                <Box sx={{ maxWidth: '900px', mx: 'auto' }}>
                  {['paragraph1', 'paragraph2', 'paragraph3'].map((para, index) => (
                    <Typography 
                      key={para}
                      paragraph={index < 2}
                      sx={{ 
                        mb: 2,
                        color: '#f5f5dc',
                        lineHeight: 1.8,
                        fontSize: { xs: '0.95rem', md: '1rem' }
                      }}
                    >
                      {t(`home.gdpr.${para}`)}
                    </Typography>
                  ))}
                </Box>
              </Card>
            </Box>
          </motion.div>
        </Section>
      </Box>
    </>
  );
};

export default Home;