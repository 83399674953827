import React from 'react';
import { Typography, Grid, Paper, Box, Container, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SchoolIcon from '@mui/icons-material/School';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ContactMailIcon from '@mui/icons-material/ContactMail';

const About = () => {
  const { t } = useTranslation();

  const sections = [
    {
      icon: <SchoolIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      title: t('about.tutorial.title'),
      items: [
        t('about.tutorial.item1'),
        t('about.tutorial.item2'),
        t('about.tutorial.item3'),
        t('about.tutorial.item4')
      ]
    },
    {
      icon: <TipsAndUpdatesIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      title: t('about.tips.title'),
      items: [
        t('about.tips.item1'),
        t('about.tips.item2'),
        t('about.tips.item3')
      ]
    },
    {
      icon: <SupportAgentIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      title: t('about.service.title'),
      items: [
        t('about.service.item1'),
        t('about.service.item2'),
        t('about.service.item3')
      ]
    }
  ];

  return (
    <>
      <Container maxWidth="lg" sx={{ py: { xs: 6, md: 8 } }}>
        {/* Header Section */}
        <Box sx={{ mb: 8 }}>
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 800,
              fontSize: { xs: '2.5rem', md: '3.5rem' },
              mb: 3,
              textAlign: 'center'
            }}
          >
            {t('about.title')}
          </Typography>
          <Paper
            elevation={0}
            sx={{
              p: { xs: 3, md: 4 },
              backgroundColor: 'background.paper',
              borderRadius: 2,
              border: 1,
              borderColor: 'divider',
              mb: 4
            }}
          >
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: t('about.introduction') }}
            />
          </Paper>
        </Box>

        {/* Main Content Sections */}
        {sections.map((section, index) => (
          <Paper
            key={index}
            elevation={0}
            sx={{
              p: { xs: 3, md: 4 },
              mb: 4,
              backgroundColor: 'background.paper',
              borderRadius: 2,
              border: 1,
              borderColor: 'divider'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              {section.icon}
              <Typography
                variant="h4"
                sx={{
                  ml: 2,
                  fontWeight: 700,
                  fontSize: { xs: '1.75rem', md: '2rem' }
                }}
              >
                {section.title}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {section.items.map((item, itemIndex) => (
                <Grid item xs={12} md={4} key={itemIndex}>
                  <Card
                    elevation={2}
                    sx={{
                      height: '100%',
                      backgroundColor: 'grey.50',
                      transition: 'transform 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: 3
                      }
                    }}
                  >
                    <CardContent>
                      <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                        {item}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}

        {/* Contact Section */}
        <Paper
          elevation={0}
          sx={{
            p: { xs: 3, md: 4 },
            backgroundColor: 'background.paper',
            borderRadius: 2,
            border: 1,
            borderColor: 'divider'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <ContactMailIcon sx={{ fontSize: 40, color: 'primary.main' }} />
            <Typography
              variant="h4"
              sx={{
                ml: 2,
                fontWeight: 700,
                fontSize: { xs: '1.75rem', md: '2rem' }
              }}
            >
              {t('about.contact.title')}
            </Typography>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                {t('about.contact.email')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                {t('about.contact.support')}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default About;