import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const renderList = (translationPath) => (
    <List dense sx={{ pl: 4 }}>
      {t(translationPath, { returnObjects: true }).map((item, index) => (
        <ListItem key={index} sx={{ display: 'list-item', listStyleType: 'disc' }}>
          <ListItemText primary={item} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <Typography variant="h2" gutterBottom>
        {t('privacy.title')}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        {t('privacy.updated')}
      </Typography>

      {/* Full GDPR Text */}
      <Box sx={{ my: 4 }}>
        <Typography variant="body1" paragraph>
          {t('home.gdpr.paragraph1')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('home.gdpr.paragraph2')}
        </Typography>
        <Typography variant="body1" paragraph>
          {t('home.gdpr.paragraph3')}
        </Typography>
      </Box>

      {/* Introduction */}
      <Box sx={{ my: 4 }}>
        <Typography variant="body1" paragraph>
          {t('privacy.introduction')}
        </Typography>
      </Box>

      {/* Data Collection */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t('privacy.dataCollection.title')}
        </Typography>
        <Typography paragraph>{t('privacy.dataCollection.content')}</Typography>
        {renderList('privacy.dataTypes')}
      </Box>

      {/* Data Usage */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t('privacy.dataUse.title')}
        </Typography>
        <Typography paragraph>{t('privacy.dataUse.content')}</Typography>
        {renderList('privacy.usagePurposes')}
      </Box>

      {/* GDPR Rights */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t('privacy.gdprRights.title')}
        </Typography>
        <Typography paragraph>{t('privacy.gdprRights.content')}</Typography>
        {renderList('privacy.userRights')}
      </Box>

      {/* Security Measures */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t('privacy.securityMeasures.title')}
        </Typography>
        <Typography paragraph>{t('privacy.securityMeasures.content')}</Typography>
        {renderList('privacy.securityList')}
      </Box>

      {/* Data Retention */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t('privacy.dataRetention.title')}
        </Typography>
        <Typography paragraph>{t('privacy.dataRetention.content')}</Typography>
        {renderList('privacy.dataRetention.periods')}
      </Box>

      {/* Changes & Contact */}
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" gutterBottom>
          {t('privacy.contact.title')}
        </Typography>
        <Typography paragraph>
          {t('privacy.contact.content')}
          <br />
          {t('privacy.contact.email')}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t('privacy.changes')}
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy; 