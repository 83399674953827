// App.js
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';
import MainLayout from './layouts/MainLayout';
import { Layout } from './components/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Profile from './pages/Profile';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import Interview from './pages/Interview';
import Recharge from './pages/Recharge';
import About from './pages/About';
import PrivateRoute from './components/PrivateRoute';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import NewFeatures from './pages/NewFeatures';
import EmailVerification from './pages/EmailVerification';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ForgotUsername from './pages/ForgotUsername';
import Admin from './pages/Admin';
import InterviewGuide from './pages/InterviewGuide';
import Research from './pages/Research';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { useEffect } from 'react';
import { checkBackendHealth } from './utils/axios';
import VideoInterview from './pages/VideoInterview';

function AppContent() {
  return (
    <MainLayout>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:uuid" element={<ProjectDetail />} />
        <Route path="/interview/:shareUrl" element={<Interview />} />
        <Route path="/about" element={<About />} /> 
        <Route path="/new" element={<NewFeatures />} /> 
        <Route 
          path="/recharge" 
          element={
            <PrivateRoute>
              <Recharge />
            </PrivateRoute>
          } 
        />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/forgot-username" element={<ForgotUsername />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/guide" element={<PrivateRoute><Layout><InterviewGuide /></Layout></PrivateRoute>} />
        <Route path="/research" element={<Research />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/video-interview/:shareUrl" element={<VideoInterview />} />
      </Routes>
    </MainLayout>
  );
}

const App = () => {
  const paypalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
    currency: "USD",
    intent: "capture",
    components: "buttons",
    debug: true  // 修改为 false
  };

  console.log("PayPal Client ID:", paypalOptions["client-id"]); // Add this line

  useEffect(() => {
    // 检查后端健康状态
    const checkHealth = async () => {
      const status = await checkBackendHealth();
      console.log('[Backend Status]', status);
      
      if (!status.isHealthy) {
        console.error('Backend is not healthy!', status);
        // 这里可以添加错误提示UI
      }
    };
    
    checkHealth();
  }, []);

  return (
    <PayPalScriptProvider options={paypalOptions} deferLoading={false}>
      <ThemeProvider theme={theme}>
        <AppContent />
      </ThemeProvider>
    </PayPalScriptProvider>
  );
};

export default App;