import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Snackbar,
  FormControlLabel,
  Checkbox,
  Box,
  CircularProgress,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Slider,
  Paper,
  Divider,
  LinearProgress,
  FormHelperText,
  InputAdornment
} from '@mui/material';
import { 
  Add as AddIcon, 
  ContentCopy as ContentCopyIcon, 
  Delete as DeleteIcon, 
  Download as DownloadIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Search as SearchIcon,
  Share as ShareIcon,
  Mic as MicIcon,
  Videocam as VideocamIcon
} from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear'; // 添加清除图标
import SearchOffIcon from '@mui/icons-material/SearchOff'; // 添加搜索无结果图标
import axiosInstance from '../utils/axios';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [recordAudio, setRecordAudio] = useState(false);
  const [recordVideo, setRecordVideo] = useState(false);
  const [currentProject, setCurrentProject] = useState({
    name: '',
    outline: '',
    recordAudio: true,
    recordVideo: false,
    interviewType: 'STRUCTURED',    // 设置默认值为结构化访谈
    interviewLanguage: 'FLEXIBLE'   // 设置默认值为灵活
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    outline: false,
    interviewType: false,
    interviewLanguage: false
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maxDuration, setMaxDuration] = useState(60);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [projectToDownload, setProjectToDownload] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axiosInstance.get('/api/projects');
      setProjects(response.data.map(project => ({
        ...project,
        project_uuid: project.project_uuid || project.uuid,
        record_audio: project.record_audio ?? false,
        record_video: project.record_video ?? false
      })));
    } catch (error) {
      setError(error.response?.data?.detail || 'Failed to fetch projects');
      if (error.response?.status === 401) {
        // If token is invalid, redirect to login
        localStorage.removeItem('token');
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [navigate]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredProjects(projects);
      return;
    }

    const query = searchQuery.toLowerCase();
    const filtered = projects.filter(project => 
      project.name.toLowerCase().includes(query) ||
      project.outline.toLowerCase().includes(query) ||
      project.project_uuid.toLowerCase().includes(query)  // 添加对UUID的搜索
    );
    setFilteredProjects(filtered);
  }, [searchQuery, projects]);

  const validateForm = () => {
    const errors = {
      name: !currentProject.name.trim(),
      outline: !currentProject.outline.trim(),
      interviewType: !currentProject.interviewType,
      interviewLanguage: !currentProject.interviewLanguage
    };
    setFormErrors(errors);
    return !Object.values(errors).some(error => error);
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      setSnackbar({ 
        open: true, 
        message: t('projects.messages.fillAllFields'),
        severity: 'warning'
      });
      return;
    }

    console.log('Submitting project with settings:', {
      name: currentProject.name,
      outline: currentProject.outline,
      interview_type: currentProject.interviewType,
      interview_language: currentProject.interviewLanguage
    });

    if (!currentProject.name || !currentProject.outline || !currentProject.interviewType || !currentProject.interviewLanguage) {
      setSnackbar({ open: true, message: t('projects.fillAllFields') });
      return;
    }

    try {
      const response = await axiosInstance.post('/api/projects', {
        name: currentProject.name,
        outline: currentProject.outline,
        check_duplicate: true,
        record_audio: currentProject.recordAudio,
        record_video: currentProject.recordVideo,
        interview_type: currentProject.interviewType,
        interview_language: currentProject.interviewLanguage,
        max_duration: maxDuration
      });
      console.log('Project creation response:', response.data);

      await fetchProjects();
      setSnackbar({ open: true, message: t('projects.saveSuccess') });
      setOpenDialog(false);
      setCurrentProject({ name: '', outline: '' });
    } catch (error) {
      console.error('Failed to save project:', error.response?.data || error);
      setSnackbar({
        open: true,
        message: error.response?.data?.detail || t('projects.saveFailed')
      });
    }
  };


  const handleCopyLink = (shareUrl, type = 'text') => {
    const baseUrl = window.location.origin;
    const url = type === 'video' 
      ? `${baseUrl}/video-interview/${shareUrl}`
      : `${baseUrl}/interview/${shareUrl}`;
      
    navigator.clipboard.writeText(url);
    setSnackbar({ 
      open: true, 
      message: t(type === 'video' ? 'projects.videoLinkCopied' : 'projects.linkCopied') 
    });
  };

  const handleDeleteClick = (project) => {
    setProjectToDelete(project);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!projectToDelete) return;

    try {
      await axiosInstance.delete(`/api/projects/${projectToDelete.id}`);
      await fetchProjects();
      setSnackbar({ open: true, message: t('projects.messages.deleteSuccess') });
    } catch (error) {
      console.error('Failed to delete project:', error);
      setSnackbar({ open: true, message: t('projects.messages.deleteFailed') });
    } finally {
      setDeleteDialogOpen(false);
      setProjectToDelete(null);
    }
  };

  const handleDownloadClick = (project) => {
    setProjectToDownload(project);
    setDownloadDialogOpen(true);
  };

  const handleDownloadConfirm = async () => {
    if (!projectToDownload) return;

    try {
      setDownloadProgress(0);
      const response = await axiosInstance.get(
        `/api/projects/export/${projectToDownload.project_uuid}`,
        {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setDownloadProgress(percentCompleted);
          },
        }
      );

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download', 
        `${projectToDownload.name}-data.zip`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);  // 清理 URL 对象
      
      setDownloadProgress(0);
      setSnackbar({ 
        open: true, 
        message: t('projects.messages.downloadComplete') 
      });
      setDownloadDialogOpen(false);
    } catch (error) {
      console.error('Download failed:', error);
      setSnackbar({ 
        open: true, 
        message: t('projects.messages.downloadFailed') 
      });
    } finally {
      setDownloadDialogOpen(false);
      setProjectToDownload(null);
      setDownloadProgress(0);
    }
  };

  const handleStartInterview = (project) => {
    navigate(`/interview/${project.id}`, {
      state: { 
        recordAudio: project.record_audio ?? false,
        recordVideo: project.record_video ?? false
      },
    });
  };

  const handleDialogOpen = () => {
    setCurrentProject(prev => ({
      ...prev,
      name: '',
      outline: '',
      recordAudio: true,
      recordVideo: false,
      interviewType: 'STRUCTURED',    // 打开对话框时重置为默认值
      interviewLanguage: 'FLEXIBLE'   // 打开对话框时重置为默认值
    }));
    setFormErrors({                   // 重置错误状态
      name: false,
      outline: false,
      interviewType: false,
      interviewLanguage: false
    });
    setOpenDialog(true);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleShare = async (projectId) => {
    try {
      const response = await axiosInstance.post(`/api/projects/${projectId}/share`);
      console.log('Share link:', response.data.share_url);
    } catch (error) {
      console.error('Error sharing project:', error);
    }
  };

  return (
    <Box sx={{ p: 0 }}>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 4,
          px: 4,
          gap: 2,  // 添加间距
          width: '100%'  // 确保容器占满宽度
        }}
      >
        <Typography 
          variant="h4" 
          sx={{ 
            color: theme => theme.palette.grey[100],
            flexShrink: 0  // 防止标题被压缩
          }}
        >
          {t('projects.title')}
        </Typography>

        {/* 搜索框容器 */}
        <Box sx={{ 
          display: 'flex',
          alignItems: 'center',
          bgcolor: 'rgba(255, 255, 255, 0.15)',
          borderRadius: 2,
          px: 2,
          flexGrow: 1,  // 允许搜索框增长填充空间
          maxWidth: 400  // 增加最大宽度
        }}>
          <SearchIcon sx={{ color: 'grey.300', mr: 1 }} />
          <TextField
            fullWidth
            variant="standard"
            placeholder={t('projects.search.placeholder')}
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              disableUnderline: true,
              style: { color: '#fff' }
            }}
            sx={{
              '& ::placeholder': {
                color: 'grey.300',
                opacity: 1
              }
            }}
          />
          {searchQuery && (
            <IconButton 
              size="small" 
              onClick={() => setSearchQuery('')}
              sx={{ color: 'grey.300' }}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      {/* 加载和错误提示 */}
      {loading && (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Alert severity="error" sx={{ mx: 4, mb: 2 }}>{error}</Alert>
      )}

      {/* 项目列表容器 - 添加最小高度确保一致的布局 */}
      <Box sx={{ 
        px: 4,
        minHeight: '60vh',  // 保持最小高度
        width: '100%',       // 确保宽度一致
        flex: 1
      }}>
        <Grid 
          container 
          spacing={3}
          sx={{
            width: '100%',
            margin: 0,
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',                     // Mobile: one column
              sm: 'repeat(2, 1fr)',          // Tablet: two columns
              md: 'repeat(3, 1fr)',          // Desktop: three columns
              lg: 'repeat(4, 1fr)'           // Large Desktop: four columns
            },
            gap: {
              xs: 2,  // Smaller gap on mobile
              sm: 3   // Normal gap on larger screens
            },
            '& > .MuiGrid-item': {
              width: '100% !important',
              padding: '0 !important',
              maxWidth: 'none !important'
            }
          }}
        >
          {/* Loading state - only show create card */}
          {loading ? (
            <Grid item>
              <Paper
                onClick={() => setOpenDialog(true)}
                sx={{
                  height: '100%',
                  minHeight: 250,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(255, 255, 255, 0.5)',
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  p: 4,
                  '&:hover': {
                    transform: {
                      xs: 'none',  // No hover effect on mobile
                      sm: 'translateY(-4px)'  // Hover effect on larger screens
                    },
                    boxShadow: {
                      xs: 2,  // Less shadow on mobile
                      sm: 4   // More shadow on larger screens
                    }
                  }
                }}
              >
                <AddIcon sx={{ 
                  fontSize: { 
                    xs: 40,  // Smaller icon on mobile
                    sm: 60   // Larger icon on desktop
                  }, 
                  color: 'grey.500', 
                  mb: 2 
                }} />
                <Typography 
                  variant="h6" 
                  color="grey.500"
                  sx={{ 
                    fontWeight: 'light',
                    fontSize: {
                      xs: '1rem',
                      sm: '1.1rem'
                    },
                    px: 3,
                    py: 1
                  }}
                >
                  {t('projects.actions.create')}
                </Typography>
              </Paper>
            </Grid>
          ) : (
            <>
              {filteredProjects.length === 0 && !loading && searchQuery ? (
                <Grid 
                  item 
                  sx={{ 
                    gridColumn: '1 / -1'  // 让无结果提示占据所有列
                  }}
                >
                  <Paper
                    sx={{
                      p: 3,
                      textAlign: 'center',
                      bgcolor: 'rgba(255, 255, 255, 0.85)',
                      width: '100%'  // 确保提示信息框占满宽度
                    }}
                  >
                    <SearchOffIcon sx={{ fontSize: 40, color: 'text.secondary', mb: 2 }} />
                    <Typography variant="h6" gutterBottom>
                      {t('projects.search.noResults')}
                    </Typography>
                    <Typography color="text.secondary">
                      {t('projects.search.tryDifferent')}
                    </Typography>
                  </Paper>
                </Grid>
              ) : (
                <>
                  {filteredProjects.map((project) => (
                    <Grid item key={project.id}>
                      <Paper 
                        elevation={2}
                        sx={{
                          height: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          background: 'rgba(255, 255, 255, 0.85)',
                          transition: 'all 0.2s ease-in-out',
                          '&:hover': {
                            transform: {
                              xs: 'none',  // No hover effect on mobile
                              sm: 'translateY(-4px)'  // Hover effect on larger screens
                            },
                            boxShadow: {
                              xs: 2,  // Less shadow on mobile
                              sm: 4   // More shadow on larger screens
                            }
                          }
                        }}
                      >
                        <CardContent sx={{ 
                          flexGrow: 1,
                          p: { 
                            xs: 1.5,  // Less padding on mobile
                            sm: 2     // Normal padding on larger screens
                          }
                        }}>
                          <Typography 
                            variant="h6" 
                            gutterBottom
                            sx={{
                              fontSize: {
                                xs: '1rem',    // Smaller font on mobile
                                sm: '1.25rem'  // Normal font on larger screens
                              },
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                              lineHeight: 1.2,
                              maxHeight: '2.4em'
                            }}
                            title={project.name}
                          >
                            {project.name}
                          </Typography>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{
                              mb: 2,
                              display: '-webkit-box',
                              WebkitLineClamp: {
                                xs: 2,  // Show fewer lines on mobile
                                sm: 3   // Show more lines on larger screens
                              },
                              WebkitBoxOrient: 'vertical',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              minHeight: {
                                xs: '2.5em',
                                sm: '4.5em'
                              }
                            }}
                          >
                            {project.outline}
                          </Typography>
                        </CardContent>
                        
                        <Divider />
                        
                        <Box sx={{ 
                          p: { 
                            xs: 1.5,  // Less padding on mobile
                            sm: 2     // Normal padding on larger screens
                          }, 
                          display: 'flex', 
                          flexDirection: 'column', 
                          gap: 1 
                        }}>
                          {/* Main action button */}
                          <Button
                            fullWidth
                            size="small"
                            startIcon={<QuestionAnswerIcon />}
                            onClick={() => navigate(`/projects/${project.project_uuid || project.uuid}`)}
                            variant="contained"
                            sx={{
                              py: { xs: 0.5, sm: 1 }  // Adjust button height
                            }}
                          >
                            {t('common.buttons.view')}
                          </Button>
                          
                          {/* Share link button */}
                          <Box sx={{ 
                            display: 'flex', 
                            gap: 1,
                            flexDirection: { 
                              xs: 'column',  // Stack buttons vertically on mobile
                              sm: 'row'      // Side by side on larger screens
                            }
                          }}>
                            <Button
                              fullWidth
                              size="small"
                              startIcon={<ContentCopyIcon />}
                              onClick={() => handleCopyLink(project.share_url)}
                              variant="outlined"
                              sx={{
                                py: { xs: 0.5, sm: 1 }  // Adjust button height
                              }}
                            >
                              {t('projects.actions.shareLink')}
                            </Button>
                          </Box>
                          
                          {/* Action buttons */}
                          <Box sx={{ 
                            display: 'flex', 
                            justifyContent: 'flex-end', 
                            gap: 1,
                            mt: { xs: 0.5, sm: 1 }  // Adjust spacing
                          }}>
                            <IconButton
                              size="small"
                              onClick={() => handleDownloadClick(project)}
                              title={t('common.buttons.download')}
                              sx={{ padding: { xs: 0.5, sm: 1 } }}  // Adjust icon button size
                            >
                              <DownloadIcon fontSize="small" />
                            </IconButton>

                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => handleDeleteClick(project)}
                              title={t('common.buttons.delete')}
                              sx={{ padding: { xs: 0.5, sm: 1 } }}  // Adjust icon button size
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </>
              )}

              {/* Create new project card */}
              <Grid item>
                <Paper 
                  elevation={2}
                  onClick={() => setOpenDialog(true)}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: 'rgba(255, 255, 255, 0.85)',
                    transition: 'all 0.2s ease-in-out',
                    cursor: 'pointer',
                    height: {
                      xs: '200px',  // Shorter height on mobile
                      sm: '300px'   // Normal height on larger screens
                    },
                    '&:hover': {
                      transform: {
                        xs: 'none',  // No hover effect on mobile
                        sm: 'translateY(-4px)'  // Hover effect on larger screens
                      },
                      boxShadow: {
                        xs: 2,
                        sm: 4
                      }
                    }
                  }}
                >
                  <CardContent sx={{ 
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <AddIcon sx={{ 
                      fontSize: { 
                        xs: 40,  // Smaller icon on mobile
                        sm: 60   // Larger icon on desktop
                      }, 
                      color: 'grey.500', 
                      mb: 2 
                    }} />
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        color: 'grey.500',
                        fontWeight: 'light',
                        fontSize: {
                          xs: '1rem',
                          sm: '1.1rem'
                        }
                      }}
                    >
                      {t('projects.actions.create')}
                    </Typography>
                  </CardContent>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {/* 创建项目对话框 */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>
          {currentProject.id ? t('projects.actions.edit') : t('projects.actions.create')}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={t('projects.fields.name')}
            value={currentProject.name}
            onChange={(e) => {
              setCurrentProject(prev => ({ ...prev, name: e.target.value }));
              setFormErrors(prev => ({ ...prev, name: false }));
            }}
            margin="normal"
            error={formErrors.name}
            helperText={formErrors.name ? t('projects.messages.nameRequired') : ''}
            required
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label={t('projects.fields.outline')}
            value={currentProject.outline}
            onChange={(e) => {
              setCurrentProject(prev => ({ ...prev, outline: e.target.value }));
              setFormErrors(prev => ({ ...prev, outline: false }));
            }}
            margin="normal"
            error={formErrors.outline}
            helperText={formErrors.outline ? t('projects.messages.outlineRequired') : ''}
            required
          />
          <Box sx={{ mt: 1, mb: 2 }}>
            <Button
              component={Link}
              to="/guide"
              color="primary"
              size="small"
              startIcon={<QuestionAnswerIcon />}
            >
              {t('projectDetail.sections.outlineHelp')}
            </Button>
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={recordAudio}
                onChange={(e) => setRecordAudio(e.target.checked)}
              />
            }
            label={t('projects.fields.recordAudio')}
          />
          <FormControl 
            fullWidth 
            margin="normal" 
            error={formErrors.interviewType}
            required
          >
            <InputLabel>{t('projects.fields.interviewType')}</InputLabel>
            <Select
              value={currentProject.interviewType}
              onChange={(e) => {
                setCurrentProject(prev => ({ ...prev, interviewType: e.target.value }));
                setFormErrors(prev => ({ ...prev, interviewType: false }));
              }}
            >
              <MenuItem value="STRUCTURED">{t('projects.interviewTypes.structured')}</MenuItem>
              <MenuItem value="SEMI_STRUCTURED">{t('projects.interviewTypes.semiStructured')}</MenuItem>
            </Select>
            {formErrors.interviewType && (
              <FormHelperText>{t('projects.messages.typeRequired')}</FormHelperText>
            )}
          </FormControl>

          <FormControl 
            fullWidth 
            margin="normal"
            error={formErrors.interviewLanguage}
            required
          >
            <InputLabel>{t('projects.fields.language')}</InputLabel>
            <Select
              value={currentProject.interviewLanguage}
              onChange={(e) => {
                setCurrentProject(prev => ({ ...prev, interviewLanguage: e.target.value }));
                setFormErrors(prev => ({ ...prev, interviewLanguage: false }));
              }}
            >
              <MenuItem value="FLEXIBLE">{t('projects.languages.flexible')}</MenuItem>
              <MenuItem value="ENGLISH">{t('projects.languages.english')}</MenuItem>
              <MenuItem value="CHINESE">{t('projects.languages.chinese')}</MenuItem>
              <MenuItem value="FRENCH">{t('projects.languages.french')}</MenuItem>
              <MenuItem value="NORWEGIAN">{t('projects.languages.norwegian')}</MenuItem>
            </Select>
            {formErrors.interviewLanguage && (
              <FormHelperText>{t('projects.messages.languageRequired')}</FormHelperText>
            )}
          </FormControl>

          <Box sx={{ mt: 3, width: '100%' }}>
            <Typography gutterBottom>
              {t('projectDetail.fields.maxDuration')}: {maxDuration} {t('common.minutes')}
            </Typography>
            <Slider
              value={maxDuration}
              onChange={(e, newValue) => setMaxDuration(newValue)}
              min={1}
              max={120}
              valueLabelDisplay="auto"
              marks={[
                { value: 1, label: '1' },
                { value: 60, label: '60' },
                { value: 120, label: '120' },
              ]}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>
            {t('common.buttons.cancel')}
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {t('common.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* 删除确认对话框 */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ 
          bgcolor: 'error.main', 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <DeleteIcon />
          {t('projects.actions.delete')}
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography>
            {t('common.messages.confirmDelete')}
          </Typography>
          {projectToDelete && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mt: 1 }}
            >
              {`"${projectToDelete.name}"`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={() => setDeleteDialogOpen(false)}
            variant="outlined"
          >
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
          >
            {t('common.buttons.delete')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Download confirmation dialog */}
      <Dialog
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ 
          bgcolor: 'primary.main', 
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}>
          <DownloadIcon />
          {t('projects.actions.download')}
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography>
            {t('projects.messages.confirmDownload')}
          </Typography>
          {projectToDownload && (
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ mt: 1 }}
            >
              {`"${projectToDownload.name}"`}
            </Typography>
          )}
          {downloadProgress > 0 && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={downloadProgress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={() => setDownloadDialogOpen(false)}
            variant="outlined"
          >
            {t('common.buttons.cancel')}
          </Button>
          <Button
            onClick={handleDownloadConfirm}
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
          >
            {t('common.buttons.download')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default Projects;