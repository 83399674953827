import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from '../utils/axios';

// Basic TTS function
function speakText(text) {
  if (!text) return;
  const utterance = new SpeechSynthesisUtterance(text);
  // Adjust voice or language if necessary:
  // utterance.lang = 'en-US';
  speechSynthesis.speak(utterance);
}

const VideoInterview = () => {
  const { shareUrl } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [project, setProject] = useState(null);

  // WebSocket reference
  const wsRef = useRef(null);
  // MediaRecorder reference
  const mediaRecorderRef = useRef(null);
  // Audio chunks
  const audioChunksRef = useRef([]);

  // UI States
  const [isInterviewing, setIsInterviewing] = useState(false);

  useEffect(() => {
    // Fetch project data
    const fetchProject = async () => {
      try {
        const resp = await axios.get(`/api/interview/${shareUrl}`);
        setProject(resp.data);
      } catch (err) {
        setError(err.response?.data?.detail || 'Failed to load project info');
      } finally {
        setLoading(false);
      }
    };
    fetchProject();
  }, [shareUrl]);

  // Initialize WebSocket after user clicks "Start Interview"
  const startInterview = async () => {
    setIsInterviewing(true);
    setError('');

    // Access microphone
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        audioBitsPerSecond: 128000
      });
      mediaRecorderRef.current.ondataavailable = (e) => {
        if (e.data.size > 0 && wsRef.current) {
          wsRef.current.send(e.data);
        }
      };
      mediaRecorderRef.current.start(1000); // send chunks every 1s
    } catch (err) {
      setError('Cannot access microphone');
      return;
    }

    // Open WebSocket
    const ws = new WebSocket(
      `${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/api/realtime-interview/${shareUrl}`
    );
    wsRef.current = ws;

    // On message, speak the text via Web Speech API
    ws.onmessage = (evt) => {
      const { text } = JSON.parse(evt.data);
      speakText(text);
    };

    ws.onerror = () => {
      setError('WebSocket error occurred');
      stopInterview();
    };
  };

  const stopInterview = () => {
    setIsInterviewing(false);

    // Stop media
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      mediaRecorderRef.current.stop();
    }
    // Close WS
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" minHeight="70vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!project) {
    return null;
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        AI Interviewer: {project.name}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" paragraph>
        Outline: {project.outline}
      </Typography>

      <Box sx={{ display: 'flex', gap: 2 }}>
        {!isInterviewing && (
          <Button variant="contained" color="primary" onClick={startInterview}>
            Start Interview
          </Button>
        )}
        {isInterviewing && (
          <Button variant="contained" color="error" onClick={stopInterview}>
            Stop Interview
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default VideoInterview;
